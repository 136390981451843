import styled from "@emotion/styled";
import type { ChangeEvent, FC } from "react";
import React, { createRef, useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  IconBloodPressureHeartWithClock,
  IconCrossXCloseMissed,
  IconLabsBloodGlucoseDropWithSugarCube,
  IconPaperclipLabValuesBeaker,
  IconUpload,
  IconWeightScale
} from "src/constants/icons";
import type { HTMLInputEvent } from "src/types/common";
import IconMeasureRuler from "src/ui/assets/icons/IconMeasureRuler";
import Translate from "src/ui/components/Translate/Translate";
import {
  AppPopup,
  AppQueryPopupsController
} from "../AppQueryPopups/AppQueryPopupsBloc";

const ActionOverlay = styled.div`
  label: ActionOverlay;
  position: fixed;
  height: 100vh;
  height: 100svh;
  inset: 0;
  z-index: 99999995;

  &[aria-hidden="true"] {
    pointer-events: none;
  }
`;

const ActionButtonTrigger = styled.button`
  label: ActionButtonTrigger;
  position: relative;
  z-index: 1;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  --size: 2.75rem;
  width: var(--size);
  height: var(--size);
  min-height: var(--size);
  min-width: var(--size);
  border-radius: 50%;
  border: 1px solid var(--color-charcoal);
  color: var(--color-charcoal);
  display: grid;
  place-items: center;
  transition:
    background-color 0.2s ease-out,
    color 0.2s ease-out;

  svg {
    width: 1.8rem;
    height: auto;
    transform: rotate(45deg);
  }

  &:hover {
    background-color: var(--color-charcoal);
    color: var(--color-white);
  }
`;

const ActionButtons = styled.div`
  label: ActionButtons;
  tab-index: 0;
  outline: none;
  position: absolute;
  left: calc(var(--left, 0px));
  bottom: calc(var(--bottom, 0px) + 0.5rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.6rem;
  transform: translateY(0.5rem);
  opacity: 0;
  transform-origin: left bottom;
  transition:
    transform 0.2s ease-out,
    opacity 0.2s ease-out;

  [aria-hidden="false"] & {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Backdrop = styled.div`
  label: Backdrop;
  position: absolute;
  inset: 0;
  background-color: rgba(66, 66, 66, 0.6);
  opacity: 0;
  transition: opacity 0.2s ease-out;

  [aria-hidden="false"] & {
    opacity: 1;
    backdrop-filter: blur(0.1rem);
  }
`;

interface MessagesActionButtonProps {
  addFiles: (files: FileList | null) => void;
}

export const AppActionButton: FC<MessagesActionButtonProps> = ({
  addFiles: addFile
}) => {
  const uploadInputRef = createRef<HTMLInputElement>();
  const actionButtonOverlay = createRef<HTMLDivElement>();
  const actionButton = createRef<HTMLButtonElement>();
  const buttonWrap = createRef<HTMLDivElement>();
  const [showOverlay, setShowOverlay] = React.useState(false);
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const highlight = query.get("hl-action");

  const handleUploadButtonClick = useCallback(() => {
    uploadInputRef.current?.click();
  }, [uploadInputRef]);

  const handleFileChange = useCallback((originalEvent: ChangeEvent): void => {
    const event = originalEvent as unknown as HTMLInputEvent;
    addFile(event.target.files);
    (originalEvent.target as HTMLInputElement).value = "";
  }, []);

  const allowedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "application/pdf"
  ];

  const reposition = () => {
    const btn = actionButton.current;
    const overlay = actionButtonOverlay.current;
    const btnWrap = buttonWrap.current;

    if (!btn || !overlay || !btnWrap) return;
    const { left } = btn.getBoundingClientRect();

    overlay.style.setProperty("--bottom", `125px`);
    overlay.style.setProperty("--left", `${left}px`);
  };

  const openOverlay = () => {
    const btnWrap = buttonWrap.current;

    if (!btnWrap) return;

    setShowOverlay(true);
    reposition();

    setTimeout(() => {
      btnWrap.focus();
    }, 100);
  };

  const handleClose = () => {
    setShowOverlay(false);
    if (highlight) {
      setTimeout(() => {
        const withoutHighlight = new URLSearchParams(window.location.search);
        withoutHighlight.delete("hl-action");
        navigate({ search: withoutHighlight.toString() });
      }, 100);
    }
  };

  useEffect(() => {
    if ((highlight && actionButtonOverlay.current, buttonWrap.current)) {
      const idExists = buttonWrap.current.querySelector(`#${highlight}`);

      if (idExists) {
        openOverlay();
      }
    }
    reposition();
  }, [highlight, actionButtonOverlay.current, buttonWrap.current]);

  return (
    <>
      <ActionButtonTrigger ref={actionButton} onClick={openOverlay}>
        <IconCrossXCloseMissed />
      </ActionButtonTrigger>
      {createPortal(
        <ActionOverlay
          ref={actionButtonOverlay}
          aria-hidden={showOverlay ? "false" : "true"}
          onClick={handleClose}
        >
          <Backdrop />
          <ActionButtons ref={buttonWrap} tabIndex={0}>
            <nine-button
              arrow=""
              size="md"
              variant={highlight === "bg" ? "fill" : "outline-fill"}
              id="bg"
              shadow="always"
              onClick={() =>
                AppQueryPopupsController.openPopup(AppPopup.enterBloodGlucose)
              }
            >
              <div slot="pre">
                <IconLabsBloodGlucoseDropWithSugarCube />
              </div>
              <Translate msg="bloodGlucose" />
            </nine-button>

            <nine-button
              arrow=""
              size="md"
              variant={highlight === "bp" ? "fill" : "outline-fill"}
              id="bp"
              shadow="always"
              onClick={() =>
                AppQueryPopupsController.openPopup(AppPopup.enterBloodPressure)
              }
            >
              <div slot="pre">
                <IconBloodPressureHeartWithClock />
              </div>
              <Translate msg="bloodPressure" />
            </nine-button>

            <nine-button
              arrow=""
              size="md"
              variant={highlight === "waist" ? "fill" : "outline-fill"}
              id="waist"
              shadow="always"
              onClick={() =>
                AppQueryPopupsController.openPopup(
                  AppPopup.enterWaistCircumference
                )
              }
            >
              <div slot="pre">
                <IconMeasureRuler />
              </div>
              <Translate msg="waistCircumference" />
            </nine-button>

            <nine-button
              arrow=""
              size="md"
              variant={highlight === "weight" ? "fill" : "outline-fill"}
              id="weight"
              shadow="always"
              onClick={() =>
                AppQueryPopupsController.openPopup(AppPopup.enterWeight)
              }
            >
              <div slot="pre">
                <IconWeightScale />
              </div>
              <Translate msg="weight" />
            </nine-button>

            <nine-button
              arrow=""
              size="md"
              variant={highlight === "lab" ? "fill" : "outline-fill"}
              id="lab"
              shadow="always"
              onClick={() =>
                AppQueryPopupsController.openPopup(AppPopup.uploadLabReport)
              }
            >
              <div slot="pre">
                <IconPaperclipLabValuesBeaker />
              </div>
              <Translate msg="upload.labReport" />
            </nine-button>

            <nine-button
              arrow=""
              size="md"
              variant={highlight === "file" ? "fill" : "outline-fill"}
              id="file"
              shadow="always"
              onClick={handleUploadButtonClick}
            >
              <div slot="pre">
                <IconUpload />
              </div>
              <Translate msg="upload.file" />
              <input
                type="file"
                accept={allowedFileTypes.join(",")}
                hidden
                multiple
                onChange={handleFileChange}
                ref={uploadInputRef}
              />
            </nine-button>
          </ActionButtons>
        </ActionOverlay>,
        document.getElementById("actionButtonTarget") as Element
      )}
    </>
  );
};
