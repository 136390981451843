import type { FC } from "react";
import React from "react";

const PlusIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
      <path
        fill="#212121"
        d="M6.3 21.15V6.85L23.267 14 6.3 21.15Zm1.1-1.65L20.435 14 7.4 8.5v4.262L12.735 14 7.4 15.238V19.5Zm0 0v-11 11Z"
      />
    </svg>
  );
};

export default PlusIcon;
