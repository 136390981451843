import type { InputChangeDetails, NineInput } from "@9amhealth/wcl";
import styled from "@emotion/styled";
import clsx from "clsx";
import type { FC } from "react";
import React, { createRef, useEffect } from "react";
import translate from "src/lib/translate";
import type { TranslationKey } from "src/types/translationKey";
import PlusIcon from "src/ui/assets/icons/PlusIcon";
import { AppActionButton } from "src/ui/components/AppActionButton/AppActionButton";
import { type ChatError } from "src/ui/components/Chat/ChatBloc";
import OnEvent from "src/ui/components/OnEvent/OnEvent";
import Translate from "src/ui/components/Translate/Translate";

const InputWrap = styled.div`
  label: ChatInputWrap;
  position: fixed;
  background-color: var(--color-gray-lighter);
  padding-bottom: calc(var(--ion-safe-area-bottom, 0px) + 55px + 0.5em);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.38, 0.7, 0.125, 1);
  left: 0;
  right: 0;
  z-index: 1000;
  --navbar-padding-bottom: 55px;
  bottom: calc(var(--from-bottom, 0px));

  @media screen and (min-width: 768px) {
    padding-top: calc(0.5em);
    padding-bottom: calc(var(--ion-safe-area-bottom, 0px) + 1em);
    bottom: var(--from-bottom, 0px);
    --navbar-padding-bottom: 0px;
  }

  &.loading {
    pointer-events: none;
    opacity: 0.5;
  }

  &:has(:focus-within) {
    --from-bottom: calc(
      var(--stored-keyboard-height, var(--navbar-padding-bottom, 0px)) - var(
          --ion-safe-area-bottom,
          0px
        ) - var(--navbar-padding-bottom, 0px)
    );
  }
`;

const FormFields = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  width: min(100%, 640px);
  padding: 0 1em 1em;
  gap: 0.6em;
`;

const ActionButtonWrap = styled.div`
  flex: 0 0 auto;
  position: relative;
  z-index: 1;
`;

const TextSpace = styled.div`
  flex: 1;
  transform: translateY(1em);
`;

const ButtonSpace = styled.div`
  width: 0;
  opacity: 0;
  transition:
    width 0.3s ease-out,
    opacity 0.3s ease-out;
  pointer-events: none;

  nine-button {
    --size: calc(var(--scale) * 3);
  }

  &.visible {
    width: 48px;
    opacity: 1;
    pointer-events: auto;
  }
`;

const BlockingError = styled.div`
  position: absolute;
  inset: 1em;
  bottom: calc(100% + 1em);
  top: auto;
  box-shadow: var(--light-shadow);
  padding: 1em 1em;
  background-color: white;
  color: var(--color-error);
  text-align: center;
  border: 2px solid var(--color-error);
  border-radius: var(--border-radius);
  z-index: 100;

  nine-button {
    margin-top: 0.5em;
  }
`;

const InlineError = styled.div`
  position: absolute;
  color: var(--color-error);
  font-size: 0.9em;
  margin-top: 0.5em;
  padding: 0.7em 1.4em;
  bottom: 100%;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--light-shadow);
  border: 1px solid var(--color-error);
  right: 2em;
  max-width: 60%;
  z-index: 1;
  word-break: break-all;
  text-wrap: balance;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

const ChatInput: FC<{
  onSubmit: (message: string, file: FileList | null) => Promise<void>;
  onChange: (wrapHeight: number) => void;
  onFocusChange: (isFocused: boolean) => void;
  errors: ChatError[];
}> = (props) => {
  const textInputRef = createRef<NineInput>();
  const wrapRef = createRef<HTMLDivElement>();
  const textValue = React.useRef("");
  const [showSendButton, setShowSendButton] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const addFiles = (files: FileList | null) => {
    if (loading) return;
    setLoading(true);
    void props.onSubmit("", files).finally(() => {
      setLoading(false);
    });
  };

  const blockingError = props.errors.find((error) => error.blocking);
  const inlineError = props.errors.filter((error) => !error.blocking);

  const handleSubmit = () => {
    const textInput = textInputRef.current;
    if (textInput) {
      if (loading) return;
      setLoading(true);
      void props
        .onSubmit(textValue.current, null)
        .then(() => {
          textInput.setValue("");
          textValue.current = "";
          setShowSendButton(false);
          localStorage.removeItem("9am.chat.unsent.input");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleValueChange = (e: CustomEvent<InputChangeDetails>) => {
    const value = e.detail.value.trim();
    textValue.current = value;
    localStorage.setItem("9am.chat.unsent.input", value);
    setShowSendButton(value.length > 0);
    const elHeight = wrapRef.current?.getBoundingClientRect().height ?? 0;
    props.onChange(elHeight);
  };

  useEffect(() => {
    const intv = setInterval(() => {
      const wrapHeight = wrapRef.current?.clientHeight ?? 0;
      if (wrapHeight === 0) return;
      props.onChange(wrapHeight);
    }, 200);

    return () => {
      clearInterval(intv);
    };
  }, [wrapRef]);

  useEffect(() => {
    if (textInputRef.current) {
      const unsent = localStorage.getItem("9am.chat.unsent.input");
      if (unsent) {
        textInputRef.current.setValue(unsent);
        textValue.current = unsent;
        setShowSendButton(unsent.length > 0);
      }
    }
  }, [textInputRef]);

  return (
    <InputWrap
      className={clsx({
        loading: loading
      })}
      ref={wrapRef}
    >
      {blockingError && (
        <BlockingError>
          <Translate msg={blockingError.message} />
          <br />
          <nine-button
            onClick={() => window.location.reload()}
            variant="fill"
            color="black"
            size="sm"
          >
            <Translate msg="retry" />
          </nine-button>
        </BlockingError>
      )}
      <OnEvent
        events={{
          nineInputChange: handleValueChange,
          nineInputFocus: () => props.onFocusChange(true),
          nineInputBlur: () => props.onFocusChange(false),
          blur: () => props.onFocusChange(false)
        }}
      >
        <nine-form>
          {inlineError.length > 0 && (
            <InlineError>
              {inlineError.map((error, i) => (
                <div key={i}>
                  {error.text
                    ? translate(error.text as TranslationKey)
                    : translate(error.message)}
                </div>
              ))}
            </InlineError>
          )}
          <FormFields>
            <ActionButtonWrap>
              <AppActionButton addFiles={addFiles} />
            </ActionButtonWrap>
            <TextSpace>
              <nine-input
                input="textarea"
                name="message"
                textarea-grow
                disabled={loading ? "true" : "false"}
                ref={textInputRef}
              ></nine-input>
            </TextSpace>
            <ButtonSpace
              className={clsx({
                visible: showSendButton
              })}
            >
              <nine-button
                type="submit"
                variant="outline-fill"
                color="sunrise-light"
                round="true"
                size="md"
                arrow=""
                disabled={loading ? "true" : "false"}
                onClick={handleSubmit}
              >
                <PlusIcon />
              </nine-button>
            </ButtonSpace>
          </FormFields>
        </nine-form>
      </OnEvent>
    </InputWrap>
  );
};

export default ChatInput;
